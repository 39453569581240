import { createSelector } from 'reselect'

export const getTravelTimeState = (state) => state.travelTime

const getRouteInfo = (state, addressId) => getTravelTimeState(state).routeInfo[addressId]
export const makeGetRouteInfo = () => createSelector([getRouteInfo], (state) => state)

const getAllRouteInfo = (state) => getTravelTimeState(state).routeInfo
export const makeGetAllRouteInfo = () => createSelector([getAllRouteInfo], (state) => state)

const getAddress = (state, addressId) =>
  getTravelTimeState(state).addresses.find((addr) => addr.id === addressId)

export const makeGetIsFetchingPublicRouteSelector = () =>
  createSelector([getAddress], (address) => address.isFetchingPublicRoute)

export const makeHasFetchedPublicRoutesSelector = () =>
  createSelector([getAddress], (address) => address.hasFetchedPublicRoute)

export const makeGetPublicRouteInfoSelector = () =>
  createSelector(
    [getRouteInfo],
    (routeInfo) => routeInfo && routeInfo.filter((route) => route.mode === 'public'),
  )

export const makeGetNonPublicRouteInfoSelector = () =>
  createSelector(
    [getRouteInfo],
    (routeInfo) => routeInfo && routeInfo.filter((route) => route.mode !== 'public'),
  )

export const getAddressesSelector = createSelector(
  [getTravelTimeState],
  (travelTime) => travelTime.addresses,
)

export const getAddressErrorSelector = createSelector(
  [getTravelTimeState],
  (travelTime) => travelTime.error,
)

export const hasCheckedStorageSelector = createSelector(
  [getTravelTimeState],
  (state) => state.hasCheckedStorage,
)

export const makeGetPublicRouteFetchError = () =>
  createSelector([getAddress], (address) => {
    if (!address) return null
    return address.routeFetchPublicError
  })

export const makeGetSelectedRouteTypeSelector = () =>
  createSelector([getRouteInfo], (routeInfo) => {
    if (!routeInfo) return null
    // fastest will always be the first in the array
    // if any other route is selected, selected will be defined, so we should return that
    // if fastest.selected === true, selected will be undefined, so we should return fastest
    const [fastest, selected] = routeInfo.filter(
      (route) => route.selected || route.type === 'fastest',
    )

    return selected || fastest || routeInfo[0]
  })
