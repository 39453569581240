const addressColors = [
  '#0063fb', // brand
  '#FF00BF', // magenta
  '#009999', // green
  '#A657F4', // purple
  '#FF4000', // red orange
]

const getAvailableColor = (addresses) => {
  const availableColor = addressColors.find((color) => {
    const idx = addresses.findIndex((addr) => addr.color === color)
    return idx === -1
  })

  return availableColor || addressColors[0] // return default color if none are available
}

export default getAvailableColor
