const removeStateProps = (addresses) =>
  addresses.map((addr) => ({
    id: addr.id,
    label: addr.label,
    alias: addr.alias,
    location: addr.location,
    postalTown: addr.postalTown,
    municipality: addr.municipality,
    color: addr.color,
  }))

export default removeStateProps
