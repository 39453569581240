/**
 * Checks whether a given addresses ID exists in a given array of addresses
 * @param {string} addressId - An address object
 * @param {Object[]} addresses - An array of address objects
 * @returns {Boolean}
 */
const addressExists = (addressId, addresses) =>
  Boolean(addresses.find((address) => address.id === addressId))

export default addressExists
