import storage from 'local-storage-fallback'

import { getAddressesSelector } from './selectors'

import getTravelTimeStorageKey from '../../utils/getTravelTimeStorageKey'
import addressExists from './utils/addressExists'
import transformAddress from './utils/transformAddress'
import removeStateProps from './utils/removeStateProps'
import getAvailableColor from './utils/getAvailableColor'

export const actionTypes = {
  APPEND_ADDRESS: 'TRAVEL_TIME/APPEND_ADDRESS',
  APPEND_ADDRESS_ERROR: 'TRAVEL_TIME/APPEND_ADDRESS_ERROR',
  ADDRESS_EXISTS_ERROR: 'TRAVEL_TIME/ADDRESS_EXISTS_ERROR',
  CLEAR_ADDRESS_ERROR: 'TRAVEL_TIME/CLEAR_ADDRESS_ERROR',
  GET_STORED_ADDRESSES: 'TRAVEL_TIME/GET_STORED_ADDRESSES',
  GET_STORED_ADDRESSES_ERROR: 'TRAVEL_TIME/GET_STORED_ADDRESSES_ERROR',
  INIT_ROUTE_FETCH: 'TRAVEL_TIME/INIT_ROUTE_FETCH',
  COMPLETE_ROUTE_FETCH: 'TRAVEL_TIME/COMPLETE_ROUTE_FETCH',
  ROUTE_FETCH_FAILED: 'TRAVEL_TIME/ROUTE_FETCH_FAILED',
  INIT_PUBLIC_ROUTE_FETCH: 'TRAVEL_TIME/INIT_PUBLIC_ROUTE_FETCH',
  COMPLETE_PUBLIC_ROUTE_FETCH: 'TRAVEL_TIME/COMPLETE_PUBLIC_ROUTE_FETCH',
  PUBLIC_ROUTE_FETCH_FAILED: 'TRAVEL_TIME/PUBLIC_ROUTE_FETCH_FAILED',
  UPDATE_ADDRESS_ALIAS: 'TRAVEL_TIME/UPDATE_ADDRESS_ALIAS',
  DELETE_ADDRESS: 'TRAVEL_TIME/DELETE_ADDRESS',
  DELETE_ROUTE_INFO: 'TRAVEL_TIME/DELETE_ROUTE_INFO',
  SET_SELECTED_ROUTE_TYPE: 'TRAVEL_TIME/SET_SELECTED_ROUTE_TYPE',
  SET_HAS_CHECKED_STORAGE: 'TRAVEL_TIME/SET_HAS_CHECKED_STORAGE',
}

export const setHasCheckedStorageAction = (bool) => ({
  type: actionTypes.SET_HAS_CHECKED_STORAGE,
  payload: bool,
})

export const appendAddressAction = (storageKey, address) => async (dispatch, getState) => {
  const addresses = getAddressesSelector(getState()) || []

  if (addresses.length >= 5) return null

  if (addressExists(address.addressId, addresses)) {
    // eslint-disable-next-line consistent-return
    return dispatch({
      type: actionTypes.ADDRESS_EXISTS_ERROR,
      payload: {
        error: 'Adressen er allerede lagret', // TODO: use error codes and map to translations
      },
    })
  }

  const transformedAddress = transformAddress(address, getAvailableColor(addresses))
  const storableAddresses = [...(removeStateProps(addresses) || []), transformedAddress]

  storage.setItem(storageKey, JSON.stringify(storableAddresses))

  return dispatch({
    type: actionTypes.APPEND_ADDRESS,
    payload: {
      address: transformedAddress,
    },
  })
}

export const clearAddressErrorAction = () => ({
  type: actionTypes.CLEAR_ADDRESS_ERROR,
})

export const getStoredAddressesAction = (storageKey) => async (dispatch, getState) => {
  const localAddresses = JSON.parse(storage.getItem(storageKey) || '[]')

  dispatch({ type: actionTypes.SET_HAS_CHECKED_STORAGE, payload: true })

  return dispatch({
    type: actionTypes.GET_STORED_ADDRESSES,
    payload: {
      addresses: localAddresses,
    },
  })
}

export const initRouteInfoFetchAction = (id) => ({
  type: actionTypes.INIT_ROUTE_FETCH,
  payload: { id },
})

export const completeRouteInfoFetchAction = (id, routeInfo) => ({
  type: actionTypes.COMPLETE_ROUTE_FETCH,
  payload: { id, routeInfo },
})

export const routeInfoFetchFailedAction = (id, error) => ({
  type: actionTypes.ROUTE_FETCH_FAILED,
  payload: { id, error },
})

export const initPublicRouteFetchAction = (id) => ({
  type: actionTypes.INIT_PUBLIC_ROUTE_FETCH,
  payload: { id },
})

export const completePublicRouteFetchAction = (id, routeInfo) => ({
  type: actionTypes.COMPLETE_PUBLIC_ROUTE_FETCH,
  payload: { id, routeInfo },
})

export const publicRouteFetchFailedAction = (id, error) => ({
  type: actionTypes.PUBLIC_ROUTE_FETCH_FAILED,
  payload: { id, error },
})

export const updateAddressAliasAction = (id, alias, prefix) => async (dispatch, getState) => {
  const addresses = getAddressesSelector(getState()) || []
  const transformedAddresses = addresses.map((addr) => (addr.id === id ? { ...addr, alias } : addr))

  storage.setItem(getTravelTimeStorageKey(prefix), JSON.stringify(transformedAddresses))
  return dispatch({
    type: actionTypes.UPDATE_ADDRESS_ALIAS,
    payload: { id, alias },
  })
}

export const deleteAddressAction = (id, prefix) => async (dispatch, getState) => {
  const addresses = getAddressesSelector(getState()) || []
  const addressIndex = addresses.findIndex((addr) => addr.id === id)
  const storableAddresses = [
    ...addresses.slice(0, addressIndex),
    ...addresses.slice(addressIndex + 1),
  ]

  storage.setItem(getTravelTimeStorageKey(prefix), JSON.stringify(storableAddresses))

  return dispatch({
    type: actionTypes.DELETE_ADDRESS,
    payload: { id },
  })
}

export const deleteRouteInfoAction = (addressId) => ({
  type: actionTypes.DELETE_ROUTE_INFO,
  payload: { addressId },
})

export const setSelectedRouteTypeAction = (addressId, travelOptionType) => ({
  type: actionTypes.SET_SELECTED_ROUTE_TYPE,
  payload: {
    addressId,
    travelOptionType,
  },
})
