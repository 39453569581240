import es6promise from 'es6-promise'
import 'isomorphic-unfetch'

es6promise.polyfill()

const isServer = typeof window === 'undefined'
const settings = isServer ? process.env : window.__DP2_GLOBALS__ // eslint-disable-line
const domain =
  settings && settings.SERVER_API ? settings.SERVER_API : 'https://profil2-api-stage.nabolag.no'
const frontendDomain = process.env.FRONTEND_SERVER_API || 'http://localhost:3000'
const commonHeaders = isServer ? { Origin: frontendDomain, Referer: `${frontendDomain}/` } : {}

const suggestLimit = 10

export const getProfile = (id, theme, attempt = 1) => {
  const url = `${domain}/profile/getProfile?id=${id}${theme ? `&theme=${theme}` : ``}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: commonHeaders,
      timeout: 3000,
    })
      .then((result) => {
        const { status, headers } = result
        if (status === 200) {
          return resolve(result)
        }
        const waitMs =
          (headers.has('retry-after') ? Number(headers.get('retry-after')) * 1000 : 250) * attempt
        if (status >= 500 && waitMs > 0 && attempt < 3) {
          return setTimeout(() => {
            return getProfile(id, theme, attempt + 1)
              .then((res) => resolve(res))
              .catch((err) => reject(err))
          }, waitMs)
        }
        return reject(result)
      })
      .catch((error) => {
        return error
      })
  })
}

export const getAddressSuggestions = (queryStr) =>
  fetch(`${domain}/AddressSearch/search?query=${queryStr}&limit=${suggestLimit}`, {
    method: 'POST',
    headers: commonHeaders,
    timeout: 10000,
  })

export const getAddressComparison = (payload) =>
  fetch(
    `${domain}/AddressSearch/compare?orderLineId=${payload.orderLineId}&lat=${payload.lat}&lon=${payload.lng}`,
    {
      method: 'POST',
      headers: commonHeaders,
      timeout: 10000,
    },
  )

export const getPoisByLatLng = (location) =>
  fetch(`${domain}/Map/radiusSearch?lat=${location.lat}&lng=${location.long}&limit=100`, {
    method: 'POST',
    headers: commonHeaders,
  })

export const getPoisByBound = (bounds) =>
  fetch(
    `${domain}/Map/geoWithinSearch?bottomLeftLat=${bounds.sw.lat}&bottomLeftLng=${bounds.sw.lng}&upperRightLat=${bounds.ne.lat}&upperRightLng=${bounds.ne.lng}&poiTypeGroup=${bounds.poiTypeGroup}`,
    {
      method: 'POST',
      headers: commonHeaders,
    },
  )

export const getTravelRoutes = (data) =>
  fetch(`${domain}/route/getTravelRoutes?id=${data.id}&lat=${data.lat}&lon=${data.lng}`, {
    method: 'GET',
    headers: commonHeaders,
    timeout: 10000,
  })

export const getPublicTransportRoutes = (data) =>
  fetch(`${domain}/route/getPublicTransportRoutes?id=${data.id}&lat=${data.lat}&lon=${data.lng}`, {
    method: 'GET',
    headers: commonHeaders,
    timeout: 10000,
  })

export const findTravelRoutes = ({ id, lat, lng }) =>
  fetch(`${domain}/route/findTravelRoutes?id=${id}&lat=${lat}&lon=${lng}`, {
    method: 'GET',
    headers: commonHeaders,
    timeout: 10000,
  })

export const findPublicTransportRoutes = ({ id, lat, lng }) =>
  fetch(`${domain}/route/findPublicTransportRoutes?id=${id}&lat=${lat}&lon=${lng}`, {
    method: 'GET',
    headers: commonHeaders,
    timeout: 10000,
  })

export const getSkiTracksByBound = (bounds) =>
  fetch(
    `${domain}/map/geoSkiTracksWithin?minLat=${bounds.sw.lat}&maxLat=${bounds.ne.lat}&minLon=${bounds.sw.lng}&maxLon=${bounds.ne.lng}`,
    {
      method: 'GET',
      headers: commonHeaders,
      timeout: 10000,
    },
  )
