const transformAddress = (
  { addressId, addressLabel, location, postalTown, municipality } = {},
  color,
) => ({
  id: addressId,
  label: addressLabel,
  alias: addressLabel,
  location,
  postalTown,
  municipality,
  color,
})

export default transformAddress
